.resizer {
    position: relative;
    background-color: #484A4B;
    display: flex;
    align-items: center;
    width: 10px;
    box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.25);
    cursor: col-resize;

    .handle {
        position: absolute;
        width: 20px;
        height: 20px;
        font-size: 10px;
        top: 0px;
        left: 0px;
        background-color: #484A4B;
        border-radius: 0.3rem;
        box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.25);
    }

    .drag {
        height: 25px;
        width: 10px;
        background: transparent;
        background-image: radial-gradient(rgb(175, 175, 175) 2px, transparent 0);
        background-size: 5px 5px;
    }
}
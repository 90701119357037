.draggable-list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1rem;
    padding: 0.5rem;
    position: relative;
    min-width: 0;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    .dragged-el {
        position: absolute;
        left:0.5rem;
        right: 0.5rem;
        pointer-events: none;
    }

    .hidden-el {
        opacity: 0;
    }
}
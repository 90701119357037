.overview {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
    justify-content: stretch;
}

.overview-topbar {
    display: flex;
    flex: 0 0 60px;
    align-items: center;
    background-color: #191b20;
    justify-content: space-between;
    padding: 0rem 1rem 0rem 0.8rem;
}

.navbar-logo {
    height: 45px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.user-avatar {
    height:38px;
    border-radius: 30px;
}

.navbar-connect {
    column-gap: 1rem;
}


.server-details {
    display: flex;
    column-gap: 2rem;
}

.music-box {
    display:flex;
    flex: 1;
    overflow-y: hidden;

    .playlists-container {
        background-color: #1f2228;
        display: flex;
        align-items: stretch;
        justify-content: stretch;
    }

    .playlist-list {
        padding: 0.8rem 1rem;
    }

    .playlists {
        flex: 1;
        height: 100%;
        position:relative;
        overflow-y: auto;
        overflow-x: hidden;

        
        /* width */
        &::-webkit-scrollbar {
            width: 10px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #6d7287;
            border-radius: 5px;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #50516c;
        }
    }

    .songs {
        flex: 1;
        overflow-y: auto;

                /* width */
        &::-webkit-scrollbar {
            width: 10px;
        }
        
        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #6d7287;
            border-radius: 5px;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #50516c;
        }
    }
}

.playlist-card, .song-card {
    display: block;
    height: 50px;
    background-color: #272a31;
    border: none;
    outline: none;
    color: white;
    border-radius: 0.3rem;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    &.active {
        filter: brightness(120%);
    }

    .play-indicator {
        position: absolute;
        left: 5px;
        top: 0;
        bottom: 0;
        font-size: 25px;
        display: flex;
        align-items: center;
        opacity:0;
        pointer-events: none;

        &.active {
            opacity: 1;
            pointer-events: all;
        }
    }
}

.playlist-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 0.5rem;
    padding: 1rem 0rem;
    padding-left: 32px;
    cursor: pointer;
    &:hover {
        .remove-button {
            opacity: 1;
            pointer-events: all;
        }
    }
}

.song-card {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0rem 0.5rem 1rem;
    column-gap: 1rem;
    align-items: center;
    &:hover {
        .remove-button {
            opacity: 1;
            pointer-events: all;
        }
    }

    .play-button {
        display:flex;
        color: white;
        font-size: 25px;
        cursor: pointer;
    }

    .left-block {
        display:flex;
        column-gap: 1rem;
        align-items: center;
    }
    .draggable {
        display:flex;
        align-items: center;
        font-size:25px;
        line-height: 25px;
        cursor:grab;
    }
}


.remove-button {
    height: 20px;
    display: flex;
    opacity:0;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    padding: 1rem 0.3rem;
    border-radius: 0.5rem;
    font-size: 40px;
    color: rgb(193, 15, 15);
    cursor:pointer;
}

.name-edit {
    cursor: pointer;
    padding: 0.2rem 0.3rem !important; 
    display:flex;
    font-size: 13px;
    align-items: center;
    justify-content: center;

    & > * {
        pointer-events: none;
    }
    opacity:0;
    pointer-events: none;
}

.card-name {
    display:flex;

    &:hover {
        .name-edit {
            opacity:1;
            pointer-events: all;
        }
    }
}

h2 {
    font-size: 20pt !important;
}

.playlist-title {
    font-size: 20pt;
    padding: 0.2rem;
    margin-right: 0.4rem;
}

.song-container {
    display: flex;
    justify-content: center;
}

.song-list {
    padding: 0.8rem 0.5rem !important;
    margin-left: 1rem;
    flex: 0 1 1000px;
    max-width: 1000px;
}

.title-container {
    border-bottom: 1px solid rgba(255, 255, 255, 0.212);

    & > .dndbtn {
        width: 30px;
        height: 30px;
    }
}
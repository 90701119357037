.music-player {
    background-color: #191b20;
    flex: 0 0 65px;
    display: flex;
    justify-content: center;
    column-gap: 0.5rem;
    align-items: center;

    & > div {
        display: flex;
        justify-content: center;
        column-gap: 0.5rem;
        align-items: center;
    }

    .musicbtn {
        background-color: transparent;
        color: rgb(198, 198, 198);
        padding: none;
        margin: none;
        border:none;
        font-size: 25px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.active {
            filter: brightness(150%);
        }
    }
}
